import store from '@/app/app-state';
import { Notification } from 'element-ui';
import { DELETE_CYCLE, FETCH_CYCLES } from '../shared/state/types/action_types';

export const fetchListCycles = async (options) => {
  await store.dispatch(FETCH_CYCLES, { ...options });
};

export const deleteCycle = async (id) => {
  try {
    const cycles = await store.dispatch(DELETE_CYCLE, id);
    await fetchListCycles({ exclude: 0 });
    Notification.success({
      title: 'Успех',
      message: 'Цикл успешно удален',
    });
    return cycles;
  } catch (e) {
    const {
      data: {
        data: { message },
      },
    } = e.response;
    Notification.error({
      title: 'Ошибка',
      message,
    });
  }
};

export const copyCycle = async () => {};

export const options = {};
