<template>
  <div class="cycles">
    <cycles-top-panel @create-cycle="openCreateModal" />
    <cycles-table />
    <cycles-modal :show.sync="showModal" />
  </div>
</template>

<script>
import CyclesTable from './cycles-table/cycles-table.vue';
import CyclesTopPanel from './cycles-top-panel/cycles-top-panel.vue';
import CyclesModal from './cycles-modal/cycles-modal.vue';

export default {
  name: 'Cycles',
  components: { CyclesTable, CyclesTopPanel, CyclesModal },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    openCreateModal() {
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-date-editor {
  width: 100% !important;
}
.form-label {
  font-weight: 500;
  margin-bottom: 0.5rem;
}
</style>
