<template>
  <el-dialog :title="title" :visible.sync="visible" width="70%" :before-close="closeModal">
    <el-form
      v-if="!cycle"
      ref="cycle"
      :model="cycle"
      label-position="top"
      @submit.prevent.native="saveFn"
    >
      <el-form-item label="Выберите период цикла">
        <el-date-picker
          v-model="cycleDate"
          type="daterange"
          size="large"
          value-format="yyyy-MM-dd"
          range-separator="-"
          start-placeholder="Дата начала цикла"
          end-placeholder="Дата окончания цикла"
          :picker-options="{ firstDayOfWeek: 1 }"
          @change="changeDatePicker"
        />
      </el-form-item>
      <el-form-item label="Состояние цикла">
        <el-switch v-model="current" active-text="Активен" inactive-text="Не активен" />
      </el-form-item>
      <el-form-item label="Блюда в цикле">
        <el-row :gutter="20">
          <el-col :span="12" :xs="24">
            <el-autocomplete
              v-model="selectGuideCycle.title"
              style="width: 100%"
              class="mb-3"
              :fetch-suggestions="searchGuidesFn"
              :debounce="600"
              placeholder="Выберите блюдо"
              value-key="title"
              @select="selectGuideFn"
            />
          </el-col>
          <el-col :span="10" :xs="20">
            <el-date-picker
              v-model="selectGuideCycle.date"
              :disabled="!cycleDate.length"
              type="date"
              format="dd MMMM yyyy"
              value-format="yyyy-MM-dd"
              placeholder="Выберите день"
              :picker-options="pickerOptions"
              @change="changeDate"
            />
          </el-col>
          <el-col :span="2" :xs="2">
            <el-button type="success" size="mini" icon="el-icon-check" @click="addGuideCycle" />
          </el-col>
        </el-row>
        <small v-if="selectError.state" class="has-text-danger">Укажите блюдо и дату</small>
      </el-form-item>
      <el-table :data="cycleGuides" style="width: 100%">
        <el-table-column prop="title" label="Название" />
        <el-table-column prop="date" label="Дата">
          <template slot-scope="scope">
            {{ scope.row.date | dateFilter }}
          </template>
        </el-table-column>
        <el-table-column :width="65">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="delGuideCycle(scope.row.id)"
            />
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <el-form v-else ref="cycle" :model="cycle" label-position="top" @submit.prevent.native="saveFn">
      <el-form-item label="Выберите период цикла">
        <el-date-picker
          v-model="cycle.date"
          type="daterange"
          size="large"
          value-format="yyyy-MM-dd"
          range-separator="-"
          start-placeholder="Дата начала цикла"
          end-placeholder="Дата окончания цикла"
          @change="changeDatePicker"
        />
      </el-form-item>
      <el-form-item label="Состояние цикла">
        <el-switch v-model="cycle.current" active-text="Активен" inactive-text="Не активен" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Закрыть</el-button>
      <el-button type="primary" :loading="loading" @click="saveFn"> Сохранить </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DATA as GUIDES_DATA } from '@/app/administration/guide/shared/state/types/getter_types';
import { FETCH_GUIDES } from '@/app/administration/guide/shared/state/types/action_types';
import moment from 'moment';
import { LOADING, SELECTED, ERROR } from '../shared/state/types/getter_types';
import { STORE_CYCLE, UPDATE_CYCLE, SELECT_CYCLE_RESET } from '../shared/state/types/action_types';
import { notifyMixin } from '../shared/mixins/notify-mixin';

export default {
  name: 'CyclesModal',
  mixins: [notifyMixin],
  props: {
    title: {
      type: String,
      default: 'Добавить цикл',
    },
    show: {
      type: Boolean,
      required: true,
    },
    action: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      cycleDate: [],
      current: false,
      day: null,
      input: null,
      cycleGuides: [],
      guide: {
        title: null,
      },
      selectGuideCycle: {
        id: null,
        title: null,
        date: null,
      },
      selectError: {
        state: false,
      },
      pickerOptions: {},
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
    ...mapGetters({ loading: LOADING, cycle: SELECTED, error: ERROR, guides: GUIDES_DATA }),
  },
  methods: {
    ...mapActions({
      store: STORE_CYCLE,
      update: UPDATE_CYCLE,
      reset: SELECT_CYCLE_RESET,
      fetchGuides: FETCH_GUIDES,
    }),
    closeModal() {
      this.visible = false;
      this.cycleDate = [];
      this.cycleGuides = [];
      this.reset();
    },
    async saveFn() {
      const [start, end] = this.cycleDate;
      switch (this.action) {
        case 'edit':
          if (this.cycle) {
            const [start, end] = this.cycle.date;
            await this.update({ start, end, current: this.cycle.current });
          }
          if (this.error) {
            this.showErrorMessage('Ошибка обновления');
          }
          this.showSuccessMessage('Успешно обновлен');
          this.closeModal();
          break;
        default:
          await this.store({ start, end, cycles: this.cycleGuides, current: this.current });
          if (this.error) {
            this.showErrorMessage('Ошибка добавления');
          }
          this.showSuccessMessage('Успешно добавлен');
          this.closeModal();
          break;
      }
    },
    changeDatePicker() {
      const [start, end] = this.cycleDate;
      this.pickerOptions = {
        disabledDate(time) {
          const t = moment(time).format('YYYY-MM-DD');
          return t < start || t > end;
        },
      };
    },
    addGuideCycle() {
      if (!this.selectGuideCycle.date || !this.selectGuideCycle.title) {
        this.selectError.state = true;
        return;
      }
      this.cycleGuides.unshift(this.selectGuideCycle);
      this.selectGuideCycle = {};
    },
    async searchGuidesFn(queryString, cb) {
      this.selectError.state = false;
      const filter = queryString ? { filter: { 'dish.title': queryString } } : {};
      await this.fetchGuides(filter);
      const data = this.guides.data.map((guide) => ({ title: guide.dish.title, id: guide.id }));
      cb(data);
    },
    selectGuideFn(guide) {
      const { id, title } = guide;
      Object.assign(this.selectGuideCycle, { id, title });
    },
    changeDate() {
      this.selectError.state = false;
    },
    delGuideCycle(id) {
      this.cycleGuides = this.cycleGuides.filter((guide) => guide.id !== id);
    },
  },
};
</script>
<style lang="scss" scoped>
.el-date-editor {
  width: 100% !important;
}
</style>
