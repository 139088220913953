<template>
  <div class="cycles-table">
    <el-table
      v-loading="loading"
      :data="data ? data.data : []"
      style="width: 100%; cursor: pointer"
      @row-dblclick="rowClick"
    >
      <el-table-column type="index" width="50" />
      <el-table-column prop="start" label="Начало цикла">
        <template slot-scope="scope">
          {{ scope.row.start | dateFilter }}
        </template>
      </el-table-column>
      <el-table-column prop="end" label="Конец цикла">
        <template slot-scope="scope">
          {{ scope.row.end | dateFilter }}
        </template>
      </el-table-column>
      <el-table-column prop="current" label="Состояние" width="100">
        <template slot-scope="scope">
          <el-tag size="medium" :type="scope.row.current ? 'success' : 'danger'">
            {{ scope.row.current ? 'Активен' : 'Не активен' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <div v-if="isAvailable" class="is-flex is-justify-content-flex-end">
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="update(scope.row)" />
            <el-button
              size="mini"
              type="info"
              icon="el-icon-document-copy"
              @click="copyCycle(scope.row)"
            />
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(scope.row.id)"
            />
          </div>
          <div v-else class="is-flex is-justify-content-flex-end">
            <el-button
              size="mini"
              type="info"
              icon="el-icon-folder-opened"
              @click="rowClick(scope.row)"
            />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="data"
      class="mt-4"
      background
      layout="prev, pager, next"
      :total="data.hasOwnProperty('meta') ? data.meta.total : 0"
      :page-size="data.hasOwnProperty('meta') ? data.meta.per_page : 0"
      @current-change="changePage"
    />
    <cycles-modal :show.sync="modalShow" title="Редактировать цикл" action="edit" />
    <el-dialog title="Копирование цикла" :visible.sync="isShowCopyModal" width="50%">
      <el-form ref="cycle" :model="cycleCopy" label-position="top" @submit.prevent.native="saveFn">
        <el-form-item label="Выберите период цикла">
          <el-date-picker
            v-model="cycleCopy.date"
            style="width: 100%"
            type="daterange"
            size="large"
            value-format="yyyy-MM-dd"
            range-separator="-"
            start-placeholder="Дата начала цикла"
            end-placeholder="Дата окончания цикла"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowCopyModal = false">Закрыть</el-button>
        <el-button type="primary" @click="saveFn"> Сохранить </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import * as moment from 'moment';
import { DATA, LOADING } from '../shared/state/types/getter_types';
import {
  DELETE_CYCLE,
  SELECT_CYCLE,
  FETCH_CYCLES,
  SELECT_CYCLE_RESET,
  COPY_CYCLE,
} from '../shared/state/types/action_types';
import { notifyMixin } from '../shared/mixins/notify-mixin';
import CyclesModal from '../cycles-modal/cycles-modal.vue';
import { fetchListCycles, deleteCycle } from './useCycleList';

export default {
  name: 'CyclesTable',
  components: { CyclesModal },
  mixins: [notifyMixin],
  data() {
    return {
      modalShow: false,
      isShowCopyModal: false,
      cycleCopy: {},
      copyElement: null,
    };
  },
  computed: {
    ...mapGetters({ data: DATA, loading: LOADING }),
    ...mapState({
      isAvailable: (state) =>
        ['super-admin', 'master-admin'].includes(state.authState.authState.role),
    }),
  },
  async mounted() {
    await fetchListCycles({ excluded: 0 });
  },
  methods: {
    ...mapActions({
      deleteFn: DELETE_CYCLE,
      select: SELECT_CYCLE,
      fetchFn: FETCH_CYCLES,
      reset: SELECT_CYCLE_RESET,
      copyC: COPY_CYCLE,
    }),
    async rowClick(cycle) {
      await this.select(cycle);
      this.$router.push({ name: 'Cycle', params: { id: cycle.id }, query: { type: 'calendar' } });
    },
    async changePage(page) {
      await await fetchListCycles({ excluded: 0, page });
    },
    async update(cycle) {
      await this.select({
        id: cycle.id,
        date: [cycle.start, cycle.end],
        current: cycle.current === 1,
      });
      this.modalShow = true;
    },
    copyCycle(item) {
      this.isShowCopyModal = !this.isShowCopyModal;
      this.copyElement = item;
    },
    async saveFn() {
      if (!this.cycleCopy.date) {
        this.$notify.error({ title: 'Ошибка', message: 'Укажите даты!', duration: 5000 });
        return;
      }
      const a = moment(this.cycleCopy.date[0]);
      const b = moment(this.cycleCopy.date[1]).subtract(1, 'day');
      const diff = b.diff(a, 'week');
      if (diff < 3) {
        this.$notify.error({
          title: 'Ошибка',
          message: 'Укажите минимум 4 недели!',
          duration: 5000,
        });
        return;
      }
      if (diff > 4) {
        this.$notify.error({
          title: 'Ошибка',
          message: 'Не стоит указывать более 4 недель!',
          duration: 5000,
        });
        return;
      }
      await this.copyC({ id: this.copyElement.id, date: this.cycleCopy.date });
      this.isShowCopyModal = !this.isShowCopyModal;
      this.copyElement = {};
      this.cycleCopy = {};
    },
    handleDelete(id) {
      this.$confirm('Вы точно хотите удалить?', 'Предупреждение', {
        confirmButtonText: 'Удалить',
        cancelButtonText: 'Закрыть',
        type: 'warning',
      })
        .then(async () => {
          await deleteCycle(id);
        })
        .catch(() => {});
    },
  },
};
</script>
