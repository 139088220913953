<template>
  <div v-if="isAvailable" class="cycles-top-panel">
    <el-row :gutter="20">
      <el-col :span="2" :offset="20">
        <el-button icon="el-icon-plus" type="primary" @click="openModal"> Добавить цикл </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CyclesTopPanel',
  computed: {
    ...mapState({
      isAvailable: (state) =>
        ['super-admin', 'master-admin'].includes(state.authState.authState.role),
    }),
  },
  methods: {
    openModal() {
      this.$emit('create-cycle');
    },
  },
};
</script>
